import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const CustumHtlmDescription = styled.div`
  p {
    font-size: ${pxToRem(16)};
    line-height: 1.4;
    color: ${colors.$262626};
  }
`;

export const ParagraphContainer = styled.p`
  margin: 0;
`;

export const Address = styled.h5`
  text-align: left;
  font-family: ${fonts.light};
  font-weight: 400;
  ${pxToCssFont(20, 30)}
  margin: 20px 0 28px;
  b {
    font-family: ${fonts.regular};
    font-weight: 700;
  }
  @media (min-width: ${breakpoints.desktop}) {
    margin: 64px 0 56px;
    b {
      font-family: ${fonts.regular};
      font-weight: 400;
    }
  }
`;

export const Title = styled(ParagraphContainer)`
  margin-top: 32px;
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(30, 38)}
  color: ${colors.$262626};

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 48px;
    ${pxToCssFont(36, 45)}
  }
`;

export const OffersList = styled.ul`
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0;

  li {
    list-style: none;
    font-family: ${fonts.regular};
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 56px;
  }
`;

export const PriceBlock = styled.div`
  header {
    display: block;
  }
`;

export const Paragraph = styled.div`
  p,
  span {
    font-style: normal;
    font-weight: 400;
    ${pxToCssFont(18, 24)}
    color: ${colors.$0d0d0d};
  }

  div {
    margin: 0;
    margin-top: 16px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      margin: 0;
      margin-top: 8px;
    }
  }
`;

export const ParagraphBold = styled(Paragraph)`
  font-weight: 700;
  ${pxToCssFont(20, 26)}
  color: #333;

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 24px;
  }
`;

export const PriceContainer = styled(Title)`
  margin-top: 12px;

  p {
    margin: 0;
  }

  .rec {
    ${pxToCssFont(24, 28)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 8px;

    .rec {
      ${pxToCssFont(24, 30)}
    }
  }
`;

export const Description = styled.div`
  p,
  span {
    ${pxToCssFont(16, 22)}
    color: ${colors.$262626};
  }

  div {
    margin: 0;
    margin-top: 8px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      margin: 0;
      margin-top: 4px;
    }
  }
`;

export const Spacer = styled.div`
  @media (min-width: ${breakpoints.desktop}) {
    padding-top: 16px;
  }
`;
