import styled, { css } from 'styled-components';
import {
  breakpoints,
  colors,
  fonts,
  getTypography,
  pxToCssFont,
  pxToRem,
} from '@vfit/shared/themes';
import { PriceVariant, PriceVariantComponent, PriceVariantForStyled } from '@vfit/shared/models';

// sunrise theme
const common = {
  amount: css`
    ${pxToCssFont(30, 38)}
    font-family: ${fonts.exbold};

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(42, 52)}
    }
  `,

  label: css`
    margin: 0;
    ${pxToCssFont(18, 24)}
  `,

  oldAmount: css`
    ${pxToCssFont(20, 32)}
    text-decoration: line-through;

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(24, 40)}
    }
  `,

  recurrence: css`
    font-family: ${fonts.exbold};
  `,

  freeAmountRecurrence: css``,

  specs: css<PriceVariantForStyled>`
    ${pxToCssFont(16, 22)}
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    color: ${({ color }) => (color === 'white' ? colors.$bebebe : colors.$7e7e7e)};

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(18, 24)}
    }
  `,

  section: css<PriceVariantForStyled>`
    ${({ color }) => (color === 'white' ? `color: ${colors.$ffffff};` : '')}
  `,

  detail: css`
    ${pxToCssFont(18, 24)}
    margin: ${pxToRem(0, 0, 8)};
  `,

  note: css`
    ${pxToCssFont(12, 16)}
    margin: 0;

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(14, 18)}
    }
  `,
};

/* used on sunrise */
const commercial = {
  ...common,
  recurrence: css`
    ${common.recurrence}
    ${pxToCssFont(24, 28)}

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(36, 45)}
    }
  `,
};

/* used on reshaping */
const details = {
  ...common,
  amount: css`
    ${common.amount}

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(36, 45)}
    }
  `,

  recurrence: css`
    ${common.recurrence}
    ${pxToCssFont(16, 22)}

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(20, 30)}
    }
  `,

  freeAmountRecurrence: css`
    padding-left: 3px;
    ${getTypography('h3.extrabold')}
  `,

  detail: css`
    ${common.detail}
    ${pxToCssFont(14, 18)}

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(18, 24)}
    }

    p {
      margin: 0;
      margin-block: 0;
    }
  `,
};

// mva theme
const mvaCommon = {
  ...common,
  label: css`
    margin: 0;
    ${getTypography('body1.bold')}
  `,

  oldAmount: css`
    font-family: ${fonts.light};
    ${pxToCssFont(20, 28)}
    text-decoration: line-through;
  `,

  amount: css`
    font-family: ${fonts.exbold};
    ${pxToCssFont(32, 40)}

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(44, 52)}
    }
  `,

  recurrence: css`
    font-weight: 700;
    ${pxToCssFont(16, 20)}

    @media (min-width: ${breakpoints.tablet}) {
      ${pxToCssFont(20, 28)}
    }

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(22, 32)}
    }
  `,

  freeAmountRecurrence: css`
    font-weight: 700;
    padding-left: 3px;
    ${pxToCssFont(32, 40)}

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(44, 52)}
    }
  `,

  detail: css`
    ${pxToCssFont(16, 20)}
    margin: ${pxToRem(0, 0, 8)};

    @media (min-width: ${breakpoints.tablet}) {
      ${pxToCssFont(18, 24)}
    }

    p {
      margin: 0;
      margin-block: 0;
    }
  `,
};

/* used on onboarding */
const mvaDetails = {
  ...mvaCommon,
};

const freeAmount = {
  ...common,
  amount: css`
    font-family: ${fonts.vodafone} !important;
    ${pxToCssFont(42, 40)}
  `,
  recurrence: css`
    font-weight: 400 !important;
    font-family: ${fonts.vodafone} !important;
    ${pxToCssFont(18, 24)} !important;
  `,
  detail: css`
    font-weight: 700 !important;
    font-family: ${fonts.vodafone} !important;
    ${pxToCssFont(18, 24)} !important;
  `,
};
export const getVariant = (component: PriceVariantComponent, variant?: PriceVariant) => {
  switch (variant) {
    case 'details':
      return details[component];
    case 'mva:details':
      return mvaDetails[component];
    case 'freeAmount':
      return freeAmount[component];
    default:
      return commercial[component];
  }
};

export const Label = styled.h4<PriceVariantForStyled>`
  ${({ variant }) => getVariant('label', variant)}
`;

export const Specs = styled.p<PriceVariantForStyled>`
  ${({ variant }) => getVariant('specs', variant)}
`;

export const Detail = styled.p<PriceVariantForStyled>`
  ${({ variant }) => getVariant('detail', variant)}
`;

export const Note = styled.p<PriceVariantForStyled>`
  ${({ variant }) => getVariant('note', variant)}
`;

export const Footer = styled.footer`
  display: flex;
  gap: ${pxToRem(20)};

  > button {
    flex-shrink: 0;
  }
`;

export const Section = styled.section<PriceVariantForStyled>`
  ${({ variant }) => getVariant('section', variant)}

  & > :last-child {
    margin: 0;
  }
`;

