import React, { Children } from 'react';
import { Accordion, Chip, CustomText, Price } from '@vfit/shared/atoms';
import { useTracking } from '@vfit/shared/data-access';
import { openPopup } from '@vfit/shared/data-access';
import { purify } from '@vfit/shared/themes';
import * as S from './productDetail.style';
import { IProductDetailProps } from './productDetail.model';
import ModalInfo from './components/ModalInfo/modalInfo';
import { PriceBlock } from './productDetail.style';

const ProductDetail: React.FC<IProductDetailProps> = ({
  details,
  address,
  showHelps = true,
  trackingOpt,
  modalInfo,
}) => {
  const { trackView } = useTracking({
    ...(trackingOpt || {
      event: ['ui_interaction'],
    }),
  });

  const [parsedAddress] = purify([address]);

  const {
    voucherPromoLabel,
    voucherPromoMessage,
    voucherPromoDetail,
    title,
    price,
    recurrence,
    description,
    offerDetails,
    conditions,
    callMeNowHelp,
    customDescriptionCVM,
    originalPrice,
  } = details;

  const clickToCall = () => {
    trackView({
      event_name: 'ui_interaction',
      event_label_track: 'click on cta',
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
    if (callMeNowHelp?.url) openPopup(callMeNowHelp?.url);
  };

  const showHelpButtons = () => (
    <div>
      {callMeNowHelp?.enable && modalInfo?.ctaName && (
        <ModalInfo
          content={{
            title: modalInfo?.title || '',
            ctaName: modalInfo?.ctaName || '',
          }}
          customAction={clickToCall}
        />
      )}
    </div>
  );

  return (
    <>
      {parsedAddress && <S.Address dangerouslySetInnerHTML={{ __html: parsedAddress }} />}
      <S.Title>{title}</S.Title>

      {voucherPromoLabel && (
        <div>
          <Chip margin={[16, 0]}>{voucherPromoLabel}</Chip>
        </div>
      )}
      <PriceBlock>
        <Price
          amount={price}
          oldAmount={originalPrice}
          recurrence={recurrence}
          detail={voucherPromoLabel ? description : ''}
        />
      </PriceBlock>
      {voucherPromoMessage && (
        <S.PriceContainer dangerouslySetInnerHTML={{ __html: voucherPromoMessage }} />
      )}
      {!voucherPromoLabel && description && (
        <S.Description>
          <CustomText textAlign="left" text={description} />
        </S.Description>
      )}
      <S.CustumHtlmDescription
        dangerouslySetInnerHTML={{
          __html: customDescriptionCVM || '',
        }}
      />
      <S.OffersList>
        {voucherPromoDetail && <div dangerouslySetInnerHTML={{ __html: voucherPromoDetail }} />}
        {Children.toArray(
          offerDetails?.map((offer) => (
            <li>
              {offer.title && <S.ParagraphBold>{offer.title}</S.ParagraphBold>}
              {offer.description && (
                <S.Paragraph>
                  <CustomText textAlign="left" text={offer.description} />
                </S.Paragraph>
              )}
            </li>
          ))
        )}
      </S.OffersList>
      {Children.toArray(
        conditions?.map((f) => (
          <Accordion
            title={f.title}
            content={[
              <CustomText
                modal
                sizeMobile={16}
                lineHeightMobile={22}
                size={16}
                lineHeight={22}
                textAlign="start"
                text={f.text || ''}
              />,
            ]}
            iconColor="#e60000"
          />
        ))
      )}
      <S.Spacer />
      {showHelps && showHelpButtons()}
    </>
  );
};

export default ProductDetail;
